<template>
  <div class="page container p-4">
    <div class="columns is-multiline is-flex-direction-row is-flex">
      <div class="column is-6 is-offset-3 has-text-centered has-text-weight-bold">
        <b-loading
          v-model="showPreload"
          :is-full-page="false"
          :can-cancel="false"
        />
      </div>
      <div
        v-if="hasErrors"
        class="column is-6 is-offset-3 has-text-centered has-text-weight-bold"
      />
    </div>
  </div>
</template>

<script>
export default {
  title: 'Iniciando sesión',
  name: 'Session',
  components: {},
  data: function () {
    return {
      hasErrors: false,
      showPreload: true,
      sessionId: null
    }
  },
  beforeMount() {
    this.sessionId = this.$router.currentRoute.params.sessionId
  },
  mounted() {
    if (this.sessionId) {
      this.initSession()
    }
  },
  methods: {
    async initSession() {
      this.hasErros = false
      try {
        let user = await this.$api.user.startSession(this.sessionId)

        await this.$store.dispatch('login', user)
        this.$api.init(user.token)

        this.showPreload = false
        await this.$router.replace('/')
      } catch (error) {
        this.hasErrors = true

        this.$msg.error(`No ha sido posible iniciar sesión, el enlace ya fue usado o no se ha encontrado el usuario.`)
        await this.$router.replace('/')
      }
    }
  }
}
</script>
